import { SwitchScreen } from "../utils/common";
import { GetDeviceType } from "../utils/screen";
import { Register } from "../utils/service";

export default (el) => {
    let params = new URLSearchParams(window.location.search);
    let regParams = Object.fromEntries(params.entries())
    el.querySelector('#TerminalName').innerText = `${regParams.tname} (${regParams.tid})`;
    el.querySelector('#HotelName').innerText = `${regParams.vname}`;
    let device = GetDeviceType()
    el.querySelector('#deviceType').innerText = device.charAt(0).toUpperCase() + device.slice(1);
    el.querySelector('#register').addEventListener('click', async (event) => {
        try {
            event.target.innerHTML = '<div class="spinner-border text-primary" role="status"></div>';
            event.target.setAttribute('disabled', true);
            let res = await Register(regParams);
            if (res.code == 200 && res.status == 'installed') {
                SwitchScreen('Congratulations')
            } else if (res.code == 200 && res.status === 'Already_installed') {
                SwitchScreen('AlreadyRegistered');
            } else if (res.code == 200 && res.status === 'inactive_profile') {
                SwitchScreen('InactiveProfile');
                // location = '/';

            } else {
                location = '/';
            }
        } catch (error) {
            console.log(error);
            e.target.innerHTML = 'Verify';
            e.target.removeAttribute('disabled');
        }
    })

    // on click of cancel
    el.querySelector('#cancel').addEventListener('click', async (event) => {

        SwitchScreen('Default')

    })

}