export default (el) => {
    let params = new URLSearchParams(window.location.search);
    let regParams = Object.fromEntries(params.entries())
    el.querySelector('#TerminalName').innerText = `${regParams.tname}`;

    el.querySelector('#setupFinish').addEventListener('click', (e) => {
        location = '/';
    })
    // el.querySelector('#setupFinish').addEventListener('click', (e) => {
    //     location = '/';
    // })
}