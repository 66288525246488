import { io } from "socket.io-client";
import { ObjectStore } from "../../glob-store";
import { SwitchScreen } from "../utils/common";
import { GetVenueDetails, PendingReservations } from "../utils/service";

export default (el) => {
    let client = io(process.env.SOCKET_ENDPOINT)
    client.on('NewReservation', () => {
        if (!ObjectStore.isVerifying) FindPendingReservations();
    })

    const FindPendingReservations = async () => {
        let data = await PendingReservations();
        if (data && data.status == 'fetched_reservation') {
            ObjectStore.isVerifying = true;
            SwitchScreen('Verification', data);
        }
    }

    const VenueDetails = async () => {
        try {
            let { Data } = await GetVenueDetails();
            let indicators = '';
            let slides = '';
            let venueData = Data.VenueData;
            let venueImages = Data.VenueImages;

            if (venueImages && venueImages.length) {
                for (let i = 0; i < venueImages.length; i++) {
                    indicators += `<li data-target="#venueSlider" data-slide-to="${i}" ${i == 0 ? 'class="active"' : ''}></li>`;
                    slides += `<div class="carousel-item${i == 0 ? ' active' : ''}"><img class="d-block w-100" src="${venueImages[i]}" alt="SlideImage_${i}"></div>`
                }
                el.querySelector('#indicators').innerHTML = indicators;
                el.querySelector('#slides').innerHTML = slides;
                ConfigureCarousel()
            }
            // ObjectStore.venueLogo = Data[0] ? Data[0] : '';
            ObjectStore.venueLogo = venueData?.VenueLogo;
            ObjectStore.venueData = venueData;
            //setting venue logo
            if (ObjectStore.venueLogo) {
                el.querySelector('#venueLogo').setAttribute('src', ObjectStore.venueLogo);
            }
        } catch (error) {
            console.log(error);
        }
    }



    const ConfigureCarousel = () => {
        let $item = $('.carousel-item');
        let $wHeight = $(window).height();
        $item.height($wHeight);
        $item.addClass('full-screen');

        $('.carousel img').each(function () {
            var $src = $(this).attr('src');
            var $color = $(this).attr('data-color');
            $(this).parent().css({
                'background-image': 'url(' + $src + ')',
                'background-color': $color
            });
            $(this).remove();
        });

        $(window).on('resize', function () {
            $wHeight = $(window).height();
            $item.height($wHeight);
        });
    }

    VenueDetails()
    FindPendingReservations();
}